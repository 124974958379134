
.conconbox{
    padding: 8px 20px;
    color: rgb(186, 0, 0);
    background-color: white;
    border: 2px solid rgb(186, 0, 0);
}
.condiv a{
    text-decoration: none;
}
.conconbox h5{
    margin-bottom: 0px;
}
.conbox{
    display: flex;
    background-color: #95002800;
    justify-content: space-around;
    padding: 20px;
}
.backwhite{
    padding: 20px 0px;
}
.context{
    display: flex;
    align-items: center;
    color: white;
}
.context h6{
    margin-bottom: 0px;
}
.w-60er{
    width: 60%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.155);
}
.texcenter{
    text-align: center;
}
.marginer{
    background-image: url(../../../public/photo/bg_pattern.png);
}
.texcenter h1{
    color: rgb(192, 3, 3);
    font-size: 42px;
    margin-bottom: 10px;
    font-family: 'Zilla Slab', serif;
}
.boxerjc1{
    border-right: 2px solid gray;
}
.boxerjc2{
    border-right: 2px solid gray;

}
.boxerjc h1{
    margin-bottom: 0px;
}
.boxerjc1 h1{
    margin-bottom: 0px;
}
.boxerjc2 h1{
    margin-bottom: 0px;
}
.imgconbox img{
    width: 70px;
    padding: 8px;
    background-color: white;
    border-radius: 50%;
    border: 4px solid rgb(186, 0, 0);
}
.imgconbox{
    margin-right: 20px;
}
.condiv{
    display: flex;
    align-items: center;
}
@media (max-width:992px){
    .conbox{
        display: block;
    }
    .condiv{
        margin-top: 10px;
        justify-content: center;
    }
}
@media (max-width:768px){
    .imgcont{
        display: block;
    }
    .imgconbox{
        display: flex;
        justify-content: center;
        margin: 0px;
        margin-bottom: 20px;
    }
    .condiv{
        margin-top: 20px;
    }
    .conbr{
        display: none;
    }
    .context{
        display: flex;
        text-align: center;
    }
}
@media (min-width: 1200px){
.contact-layout1 .contact-panel__form, .contact-layout2 .contact-panel__form {
    padding-right: 10px;
}
}