.serimghome img{
    width: 100%;
}
.readmore{
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
}
.nameservice h6{
    margin-top: 10px;
    font-weight: 600;
}
.servicebox{
    padding:0px 5px;
}
.serviceboxin{
    border: 2px solid #ffd2de;
    padding: 8px;
    background-color: #fff9f9;
    transition: 0.4s;
}
.serviceboxin:hover{
    margin-top: -15px;
    box-shadow: 6px 6px 6px rgba(128, 128, 128, 0.533);
}
.contentinser{
    height: 130px;
}
@media (max-width:1400px){
    .contentinser{
        height: 150px;
    } 
}
@media (max-width:992px){
    .contentinser{
        height: 135px;
    } 
}
@media (max-width:768px){
    .contentinser{
        height: 150px;
    } 
}
@media (max-width:580px){
    .contentinser{
        height: auto;
    } 
}