.rowhome{
    display: flex;
    width: 100%;
}
.rowhomehi{
    width: 100%;
    overflow: hidden;
}
.relative{
    position: relative;
}
.absul{
    position: absolute;
    z-index: 2;
}
.latestpost{
    height: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    width: 160px;
    border-top: 50px solid #fa99b3;
    border-left: 16px solid #950028;
    border-right: 16px solid #950028;
}
.latestposth4{
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    width: 160px;
}
.seclater{
    border: 16px solid #950028;
    border-top: 50px solid #950028;
    position: relative;
    padding: 10px;
}
.label{
    color:black;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 0px 4px 0px;
}
.inputhome{
    width: 100%;
    height: 32px;
}
.texthome{
    width: 100%;
    height: 90px;
}
.linkabout{
    margin-top: 20px;
    padding: 8px 28px;
    background-color: #e4194f;
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    border-radius: 10px;
    box-shadow:3px 3px 3px rgba(255, 0, 0, 0.189);
}
.linkabouth{
    display: flex;
    justify-content: center;
}
.ulofscreener{
    height: 100px;
    overflow: hidden;
}