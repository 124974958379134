.backerfixs{
    background-attachment: fixed;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image:linear-gradient(#e4194fd5,#e4194fd1),url(../../../public/photo/aaaaa.jpg);
}
.serviceimg img{
    width: 100%;
}
.moniter{
    text-decoration: none;
    text-align: center;
}
.movingser{
    background-color: white;
    text-decoration: none;
}
.movingser h6{
    color: black;
    text-decoration: none;
}
.movingser h5{
    color: black;
    text-decoration: none;
}
.innertexth{
    position: relative;
}
.rowhome{
    display: flex;
    width: 100%;
}
.rowhomehi{
    width: 100%;
    overflow: hidden;
}
.innertexth h6{
    color: white;
}
.displayflex{
    display: flex;
}
.fixrowser{
    margin-bottom: 30px;
    color: white;
}
.contactfix{
    text-decoration: none;
    background-color: white;
    display: flex;
    color: rgb(9, 9, 146);
    width: 150px;
    align-items: end;
    justify-content: center;
    margin-top: 40px;
}
.contactfix h6{
    color: rgb(9, 9, 146);
    margin-bottom: 0px;
    display: flex;
    padding: 10px 20px;
}
.back#F4DB7D{
    background-color: rgb(255, 255, 179);
}
@media (max-width:580px){
    .innertexth{
        margin-top: 20px;
    }
}