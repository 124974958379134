.backerfix{
    height: 500px;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image:linear-gradient(#e4194fd5,#e4194fd1),url(../../../public/photo/gooler.jpg);
}
.textinbackfix h3{
    color: white;
    text-align: center;
    align-items: center;
    font-size: 32px;
}
.textinbackfix p{
    color: white;
    text-align: center;
    align-items: center;
    font-size: 14px;
}
.aaaaaaaa{
    background-image: url(../../../public/photo/aaaaaaaa.jpg);
}
.linkinboxfix{
    color: white;
    text-decoration: none;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.linkinboxfix h6{
    display: flex;
    margin-top: 8px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color:#db003a ;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid white;
}