.boxadd{
    padding: 5px;
    display: flex;
}
.boxaddin{
    width: 100%;
    background-color: #7b0021;
    padding: 10px;
    height: 100px;
    justify-content: center;
}
.homecalla img{
    width: 36px;
    background-color: white;
    padding: 2px;
    border-radius: 50%;
}
.imagersma{
    filter: grayscale(white);
}
.snjkascnmc{
    display: none;
}
@media(max-width:992px){
    .snjkascnmc{
        display: block;
    }
}