.textinhos  h3{
    margin-bottom: 22px;
    font-weight: 600;
    font-family: 'Zilla Slab', serif;
    font-size: 32px;
}
.knowmore{
    text-decoration: none;
    color: rgb(209, 1, 1);
    font-weight: 600;
    display: flex;
}
.textinhos p{
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
}
.margintop{
    position: relative;
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 20px;
    background-image: radial-gradient(rgba(255, 255, 255, 0.964),rgba(255, 255, 255, 0.751)), url(../../../public/photo/bg_pattern.png);
}
.w-60er{
    position: relative;
    z-index: 2;
    margin-top: -150px;
}
.textinhos h6{
    color: rgb(209, 1, 1);
    margin-bottom: 22px;
    font-family: 'Zilla Slab', serif;
}
.knowmore h6::after{
    display: flex;
    content: " ";
    padding-bottom: 6px;
    border-bottom: 4px solid red;
}
.hospitalinohome img{
    width: 100%;
    box-shadow: 8px 8px 8px rgba(128, 128, 128, 0.495);
}
.hospitalinthome img{
    width: 100%;
    box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.495);
    border: 0.2px solid rgba(0, 0, 0, 0.655);
}
.w-60er{
    margin-bottom: 30px;
}
.relative{
    position: relative;
}
.aboibdsj{
    background: linear-gradient(rgba(255, 255, 255, 0.888),rgba(255, 255, 255, 0.926)), url(../../../public/photo/abouter.jpg);
    background-position: center;
    background-size: cover;
}
.sgcbjsdhfcdnz{
    background-image: url(../../../public/photo//bannn.jpg);
}
@media (max-width:992px){
    .homemt-5{
        margin-top: 50px;
    }
}
.appoitmenter{
    position: fixed;
    bottom: 0px;
    z-index: 50;
    display: none;
}
.backolor{
    background-color: #1f3d9d;
    display: flex;
    justify-content: center;
}
.widthers{
    width: 220px;
    font-weight: 600;
    color: white;
}
.iconin-box{
    width: 50px;
    margin: 10px;
}
.iconin-box img{
    width: 50px;
}
.buttonetrtr{
    display: flex;
    justify-content: space-between;
}
@media (max-width:992px){
    .appoitmenter{
        display: block;
    }
}