.piletratmentsbanner{
    background-image: url(../../../public/photo/fbannero.jpg);
    background-position: center;
    background-size: cover;
}
.widthedoc{
    height: 1200px;
    overflow-y: hidden;
    overflow: scroll;
}
.widget:after {
    content: "";
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 0;
    width: 3px;
    background-color:#ffff00;
}

element.style {
    background-color: rgb(123, 0, 33);
}
.widget:last-child {
    margin-bottom: 0;
}
.widget{
    padding: 20px;
}
.alianer{
    box-shadow: 8px 8px 8px rgba(123, 0, 33, 0.484);
}
.bg-overlay-primary-gradient:before {
    opacity: 0.95;
    background-color: transparent;
    background-image: linear-gradient(rgb(123, 0, 33));
}
.piletratmentsbanner1{
    background-image:url(../../../public/photo/onner.jpg);
}
.piletratmentsbanner2{
    background-image: url(../../../public/photo/pb2.jpg);
}
.piletratmentsbanner3{
    background-image: url(../../../public/photo/pb3.jpg);
    background-size: cover;
}
.piletratmentsbanner4{
    background-image: url(../../../public/photo/pb4.jpg);
    background-size: cover;
}
.piletratmentsbanner5{
    background-image: url(../../../public/photo/pb5.jpg);
    background-size: cover;
}
.piletratmentsbanner6{
    background-image: url(../../../public/photo/pb6.jpg);
    background-size: cover;
}
.piletratmentsbanner7{
    background-image: url(../../../public/photo/pb7.jpg);
    background-size: cover;
}
.piletratmentsbanner8{
    background-image: url(../../../public/photo/pb8.jpg);
    background-size: cover;
}
.piletratmentsbanner9{
    background-image: url(../../../public/photo/pb9.jpg);
    background-size: cover;
}
.piletratmentsbanner10{
    background-image: url(../../../public/photo/pb10.jpg);
    background-size: cover;
}
.piletratmentsbanner11{
    background-image: url(../../../public/photo/pb11.jpg);
    background-size: cover;
}
.piletratmentsbanner12{
    background-image: url(../../../public/photo/pb12.jpg);
    background-size: cover;
}
.piletratmentsbanner13{
    background-image: url(../../../public/photo/pb13.jpg);
    background-size: cover;
}
.piletratmentsbanner14{
    background-image: url(../../../public/photo/pb14.jpg);
    background-size: cover;
}
.piletratmentsbanner15{
    background-image: url(../../../public/photo/pb15.jpg);
    background-size: cover;
}
.piletratmentsbanner16{
    background-image: url(../../../public/photo/pb16.jpg);
    background-size: cover;
}
.piletratmentsbanner17{
    background-image: url(../../../public/photo/pb17.jpg);
    background-size: cover;
}
.piletratmentsbanner18{
    background-image: url(../../../public/photo/pb18.jpg);
    background-size: cover;
}
.piletratmentsbanner19{
    background-image: url(../../../public/photo/pb19.jpg);
    background-size: cover;
}
.piletratmentsbanner20{
    background-image: url(../../../public/photo/pb20.jpg);
    background-size: cover;
}
.piletratmentsbanner21{
    background-image: url(../../../public/photo/pb23.jpg);
    background-size: cover;
}
.contactusbanner{
    background-image: url(../../../public/photo/contactusbanner.jpg);
    background-size: cover;
}
.gallerybanner{
    background-image: url(../../../public/photo/gal.jpg);
    background-size: cover;
}
.blogedr{
    background-image: url(../../../public/photo/blog.jpg);
    background-size: cover;
}
.hiknjkbhm{
    aspect-ratio: 3/1.8;
}
.piletratmentsbannerd{
    background-image: url(../../../public/photo/vvv.jpg);
    background-size: cover;
}
