.reviewimg img{
    width: 90%;
    border-radius: 30px;
    box-shadow: 30px 30px #950028;
}
.reviewimg{
    padding-bottom: 50px;
}
.reviewimga{
    width: 100%;
    position: absolute;
    width: 100%;
    right: 0px;
    bottom: 0px;
}
.centerboxer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.reviewimga img{
    width: 200px;
    z-index: 1;
    display: flex;
    bottom: 0px;
    border: 5px solid white;
    border-radius: 20px;
}
.reviewimga{
    display: flex;
    justify-content: end;
}
.centerdis{
    display: flex;
    align-items: center;
    justify-content: center;
}
.textcenter{
    display: flex;
    text-align: center;
    justify-content: center;
}
.ussersph{
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 20px;
    border: 0px;
    background-image: url(../../../public/photo/user.png);
    position: relative;
    z-index: 1;
}
.textinre{
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.reviewinter{
    padding: 30px;
    padding-top: 40px;
    padding-bottom:40px;
    background-color: #950028;
    margin-left: 70px;
    position: relative;
    z-index: 1;
    margin-top: 35%;
}
.textarere{
    height: 100px;
    width: 100%;
    margin-top: 10px;
}
.frfeviewuser{
    display: flex;
    justify-content: center;
}
.centerul{
    justify-content: center;
    margin-bottom: 10px;
}
.centerreviewt h5{
    color: white;
}
.centerreviewt{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.innfoinrev h5{
    color: #950028;
}
.submiter{
    padding: 8px 20px;
    border: 2px solid black;
    font-size: 16px;
}
.submiter h6{
    color: black;
    font-size: 16px;
    margin-bottom: 0px;
}
.submiterdiv{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
@media (max-width:1200px){
    .reviewinter{
        margin-left: 30px;
        margin-top: 50%;
    }
}
@media (max-width:992px){
    .reviewinter{
        margin-left: 30px;
        margin-top: 40%;
        margin-bottom: 20px;
    }
    .alianjghhj{
        display: "none";
    }
}
@media (max-width:768px){
    .reviewinter{
        margin-left: 10px;
        margin-top:20px;
        margin-bottom: 20px;
    }
    .reviewimga img{
        width: 95%;
        z-index: 1;
        display: flex;
        bottom: 0px;
        border: 5px solid white;
        border-radius: 20px;
    }
    .reviewimg img{
        box-shadow: 0px 0px 0px white;
    }
}