.boxi{
    background-color: rgba(128, 128, 128, 0.784);
    position: fixed;
    width: 100%;
    height: 100%;
}
.boxia{
    background-color: rgba(128, 128, 128, 0.784);
    position: fixed;
    width: 100%;
    height: 100%;
}
.big{
    display: block;
}
.small{
    display: none;
}
@media (max-width:992px){
    .big{
        display: none;
    }
    .small{
        display: block;
    }
}
.alianer-imger img{
    width: 100%;
}
.alianer-imger{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    z-index: 2;
}
.relaterc{
    position: relative;
}
.right-arrow{
    right: 20px;
}
.left-arrow{
    left: 20px;
}