.logobox img{
    width: 105px;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
}
.logoboxe img{
    width: 78px;
}
.headertop{
    border-bottom: 0.1px solid #e4194f6e;
    position: relative;
}
.linkbox{
    display: flex;
}
.outerfirst{
    background-color: #e4194f;
}
.headersec{
    display: flex;
    padding: 5px 0px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.headerlink{
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 10px 10px;
}
.linkappiont{
    display: flex;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 10px 20px 12px;
    background-color: #e4194f;
    align-items: center;
    border-radius: 10px;
    font-weight: 500;
}
.homecall img{
    width: 28px;
    background-color: white;
    padding: 2px;
    border-radius: 50%;
}
.textimg{
    display: flex;
    align-items: center;
}
.textimg h6{
    color: white;
    margin-bottom: 0px;
    margin-left: 10px;
}
.paddimg img{
    margin: 0px 3px;
}
.display-flex{
    display: flex;
}
.menu img{
    width: 30px;
}
.menuouter{
    background-color: white;
    border: 0px;
    display: none;
}
@media (max-width:1200px){
    .linkappiontd{
        display: none;
    }
}
@media (max-width:992px){
    .linkbox{
        display: none;
    }
    .menuouter{
        display: block;
    }
    .display-flex{
        display: none;
    }
}
@media (max-width:580px){
    .hidderinhed{
        display: none;
    }
}
@media (max-width:450px){
    .firsticon:nth-child(3){
        display: none;
    }
    .firsticon:nth-child(4){
        display: none;
    }
    /* .firstphone h6:nth-child(2){
        display: none;
    } */
}
.removete{
    text-decoration: none;
}
.btn__primary {
    color: #ffffff;
    background-color: #21cdc0;
    display: flex;
    border-radius: 50px;
    align-items: center;
}
.btn__primary:hover{
    color: white;
}
.btn__primarya {
    color: #ffffff;
    background-color: #7b0021;
    display: flex;
    border-radius: 50px;
    align-items: center;
}
.color-secondary {
    color: #7b0021;
}
.btn__primarya:hover{
    color: white;
}
.imgerlog{
    border-radius: 50%;
    width: 94px;
}
.headersi{
    display: flex;
    align-items: center;
}
@media (max-width: 991px){
.header .navbar, .header .navbar > .container, .header .navbar > .container-fluid, .header-transparent .navbar, .header-transparent .navbar > .container, .header-transparent .navbar > .container-fluid {
    height: 100px;
    background-color: white;
}
.headersi{
    display: none;
}
}
.marginerser{
    margin-top: 100px;
}
@media(max-width:1200px){
    .marginerser{
        margin-top: 0px;
    }
}
.rowhomer{
    width: 100%;
    overflow: hidden;
    height: auto;
}
.backimger{
    background-image:linear-gradient(rgba(255, 255, 255, 0.275),rgb(255, 255, 255)), url(../../../../public/photo/0.png);
    background-size: cover;
}
.footer .footer-secondary:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 50px;
    height: 5px;
    background-color: #7b0021;
}
.footer .footer-widget-contact:after {
    content: "";
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 0;
    width: 3px;
    background-color: #e4194f;
}
.textheigt{
    height: 110px;
}
.chngershdgc:hover{
    background-color: yellow;
    color: #7b0021;
}
@media(max-width:1200px){
    .textheigt{
        height: 165px;
    }
}
@media(max-width:992px){
    .textheigt{
        height: 110px;
    }
}
@media(max-width:768px){
    .textheigt{
        height: auto;
    }
}
@media (min-width: 320px) and (max-width: 575px){
.slider .slide-item {
    padding: 0px 0px;
}
}
.biger{
    display: block;
}
.smaller{
    display: none;
}
@media(max-width:992px){
    .biger{
        display: none;
    }
    .smaller{
        display: block;
    }
}
.gridss{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: masonry;
  }
  @media(max-width:992px){
    .gridss{
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: masonry;
      }

  }
  .gridss img{
    height: auto;
    width: 100%;
  }
  .wweddn{
    display: grid;
    grid-template-columns: repeat(1,1fr);
  }
  .talkaer img{
    margin-bottom: 10px;

  }

