.orthocen{
    text-align: center;
    color: white;
    margin-bottom: 20px;
}
.orthocen h1{
    margin: 16px 0px;
}
.footmapdi{
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
}
.footmap{
    width: 100%;
    height: 140px;
}
.rowhome{
    display: flex;
    width: 100%;
}
.rowhomehi{
    width: 100%;
    overflow: hidden;
}
.reviewp{
    padding-left: 5px;
    padding-right: 5px;
}
.resection{
    margin-bottom: 70px;
    margin-top: 60px;
}
.review{
    color: #30327d;
    margin-top: 30px;
    position: relative;
}
.google{
    width: 200px;
}
.reviewdis{
    display: flex;
    width: 100%;
}
.overreview{
    overflow: hidden;
    width: 100%;
    position: relative;
}
::-webkit-scrollbar{
    display: none;
}
.usser{
    border-radius: 20px;
    width: 60px;
    margin-right: 10px;
}
.star{
    width: 20px;
    margin: 5px;
}
.startbox{
    display: flex;
    list-style-type: none;
    justify-content: left;
    padding-left: 0px;
    margin-bottom: 0px;
}
.mainreview{
    display: flex;
    justify-content: left;
}
.conre h6{
    margin-bottom: 0px;
    margin-left: 5px;
}
.centerre{
    display: flex;
    align-items: center;
}
.arrowr{
    position: absolute;
    z-index: 2;
    width: 20px;
}
.headre{
    position: absolute;
    top: 50%;
    left: 95%;
}
.headle{
    position: absolute;
    top: 50%;
}
.relpo{
    position: relative;
}
.blogbox{
    border-top: 50px solid #950028;
    padding-top: 30px;
}
.minerblog {
    position: absolute;
    z-index: 2;
}
.rowofblogp{
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
}
.homeblog{
    color: white;
    background-color: #950028;
    height: 473px;
}
.homeblog h5{
    margin: 50px 0px;
}
.homeblog h1{
    margin-bottom: 50px;
}
.testisec{
    display: flex;
}
.testfirst{
    display: none;
}
.continerniner{
    height: 100%;
    display: flex;
    align-items: center;
}
@media (max-width:768px){
    .rowofblogp{
        position: relative;
        width: 100%;
        margin: 0px;
        text-align: center;
    }
}
@media (max-width:1200px){
    .homeblog{
        height: 490px;
    }
}
@media (max-width:992px){
    .homeblog{
        height: 492px;
    }
    .testisec{
        display: none;
    }
    .testfirst{
        display: flex;
    }
    .blogbox{
        border-top: 0px;
    }
    .maderder{
        padding-left: 20px;
    }
}
@media (max-width:768px){
    .homeblog{
        height: auto;
    }
    .relpo{
        width: 100%;
        padding: 0px;
    }
    .minerblog{
        position: relative;
        z-index: 2;
    }
    .homeblog h5{
        margin: 30px 0px;
    }
    .homeblog h1{
        margin-bottom: 30px;
    }
}
.hilandrere{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
}
.alinerliber{
    margin-left: -50px;
}
